import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
//import USMap from "views/test/usMap.js";
//import MapChart from "views/test/usMap2.js";
import USMap from "./usMapAI";
//import scss
import "assets/css/pages/section3.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/



export default class Section3 extends Component {
	constructor() {
		//do nothing
		super();
		console.log('loaded');
	}
	componentDidMount() {
		console.log('test');
	}
	render() {
		return (
			<div className={classNames("section", "map-section")} id="reviews" name="reviews">
				<div className={classNames("row", "map-container-outer")}>
					<div className={classNames("row", "map-title")}>
						<h2>What other military homeowners are saying</h2>
					</div>
					<USMap />
				</div>
			</div>
		);
	}

}