/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
//import Favorite from "@material-ui/icons/Favorite";
import Button from 'components/CustomButtons/Button.js';


import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

function submitBasicForm(e) {
  e.preventDefault();
  var formId = 'subscribe-form1';
  var data, dataVals = {}, notes = '', keyArray = {}, excludeArray = {}, result = {};
  var trackingParamsRaw = {}, trackingParams, utm_source_param;
  //add validation?
  let pageTitle = document.title ? document.title : null;
  let form = document.getElementById(formId);
 
  //show spinner
  let submissionSpinner = form.querySelector(".submission-spinner") ? form.querySelector(".submission-spinner") : null;
  if (submissionSpinner) {
    submissionSpinner.style.opacity = 1;
    submissionSpinner.style.display = "flex";
  }

  //show spinner
  let submitBtn = form.querySelector("button[type='submit']") ? form.querySelector("button[type='submit']") : null;
  if(submitBtn) {
    submitBtn.disabled = true;
    submitBtn.style.cursor = 'wait';
    submitBtn.classList.add('no-hover');
    let buttonMsg =submitBtn.querySelectorAll('.MuiButton-label span');
    for(let z=0; z<buttonMsg.length; z++){
      buttonMsg[z].style.opacity = 0.3;
    }
  }

  //create form data
  data = new FormData(form);

  try {
    trackingParamsRaw = getWithExpiry('queryParameters');
  } catch {
    console.log('no tracking');
  }



  for (var [key, value] of data) {
    //&& !excludeArray.includes(key)
    //check if email address is not empty
    if(key == 'email' && value ==''){
      alert('Please provide an email address to subscribe to our email list and try again.');
      //hide spinner
      if (submissionSpinner) {
        submissionSpinner.style.opacity = 0;
        submissionSpinner.style.display = "none";
      }
      //revert submit btn
      if(submitBtn) {
        submitBtn.disabled = false;
        submitBtn.style.cursor = 'pointer';
        submitBtn.classList.remove('no-hover');
        let buttonMsg =submitBtn.querySelectorAll('.MuiButton-label span');
        for(let z=0; z<buttonMsg.length; z++){
          buttonMsg[z].style.opacity = 1;
        }
      }
      return false;
    }
    if (key in dataVals) {
      value = data.getAll(key);
      
      data.set(key, value);
      excludeArray[key] = value;
      console.log('duplicate value');
      console.log(value);
    }

    dataVals[key] = value;
    //keyArray.push(key);
    //console.log(key, value)
    result[key] = { "id": key, "prompt": key, "value": value };
  }

  if (trackingParamsRaw == null) {
    trackingParamsRaw = { "testVar": true };
  }

  if (trackingParamsRaw) {
    trackingParams = trackingParamsRaw;
    data.append('info', JSON.stringify(trackingParams));
    utm_source_param = trackingParams['utm_source1'] ? trackingParams['utm_source1'] : null;
  } else {
    data.append('info', JSON.stringify(trackingParamsRaw));
  }

  //append notes to form data
  dataVals['GeneralNotes'] = notes;
  data.append('GeneralNotes', notes);
  data.append('formName', formId);
  data.append('pageTitle', pageTitle);
  data.append('results', JSON.stringify(result));
  /*
  //TEST MODE
  formConfirmation(formId, dataVals);
  form.reset();
  return;
  //TEST MODE
  */

  //set up request
  const XHR = new XMLHttpRequest();

  // Define what happens on successful data submission
  XHR.addEventListener("load", (event) => {
    console.log(event.target.responseText);

    formConfirmation(formId, dataVals);
    form.reset();
  });

  // Define what happens in case of error
  XHR.addEventListener("error", (event) => {
    console.log("Oops! Something went wrong.");
    console.log(event.target.responseText);
  });

  // Set up our request
  XHR.open("POST", "https://wevettrealty.com/custom-includes/php/mailing_list_subscription.php");

  // The data sent is what the user provided in the form
  XHR.send(data);

  return false;
}

export default function Footer(props) {
  const { children, content, theme, big, className } = props;
  const classes = useStyles();
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>

      <div className="footer-container">
        <div className="footer-upper">
          <div className="row align-v">
            <div className="col-4">
              <a href="https://wevettrealty.com/home">
                <img className="logo-wide" alt="WeVett Realty" src={require('assets/img/wevettrealty/WVR-white.png')} width="300" />
              </a>
            </div>
            <div className="col-4 footer-socials">
              <ul className={classes.socialButtons}>
                <li>
                  <Button justIcon simple href="https://www.linkedin.com/company/wevett" color="linkedin">
                    <i className="fab fa-linkedin" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="https://www.facebook.com/wevett" color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="https://www.youtube.com/channel/UCAUnG5z0pnx4xPQkHLR5eGA" color="youtube">
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple href="https://instagram.com/we_vett" color="instagram">
                  <FontAwesomeIcon icon="fa-brands fa-square-instagram"/>
                  </Button>
                </li>
              </ul>
            </div>
            <div className="col-4">
              <div className="subscribe-div left-aligned">
                <h4> Stay Updated</h4>
                <div>
                  <p>
                    Receive WeVett education, news, and updates in your inbox.
                  </p>
                </div>
                <form id="subscribe-form1" name="subscribe-form1" onSubmit={submitBasicForm}>

                  <div className={classNames("text-input", "hide-after-submit")}>
                    <input type="email" name="email" placeholder="Your Email" />
                  </div>
                  <Button className={classNames("transparent-on-white", "hide-after-submit")} type="submit">
                    <span>Subscribe</span>
                    <div className={classNames("submission-spinner")} >
                    <div className={classNames("lds-dual-ring")} />
                  </div>
                  </Button>
                  
                  <div className={classNames("confirm-message-wrapper")} >
                    <span>Thanks for subscribing!</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <hr className="hr-lightgrey" />
        </div>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          " "
        )}
        {content}
        <div className="disclaimer left-aligned">
          <div>
            <p>Copyright © 2023 WeVett Realty. All Rights Reserved.</p>
            <p>KREC #CO00003195 Supporter of the Fair Housing Act and the Equal Opportunity Act.</p>
          </div>
          <div className="fair-housing-icon">
            <img src={require('assets/img/icons/EOH_4x.png')} alt="Equal Opportunity Housing Act" />
          </div>
        </div>
        <div className={classes.clearFix} />
      </div>

    </footer>
  );
}

Footer.propTypes = {
  theme: PropTypes.oneOf(["dark", "white", "transparent"]),
  big: PropTypes.bool,
  content: PropTypes.node.isRequired
};
