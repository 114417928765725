import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
//import scss
import "assets/css/pages/section5.scss";
import Accordion from "components/Accordion/Accordion.js";

export default class Section5 extends Component {
	constructor() {
		//do nothing
		super();
		console.log('loaded');
	}
	componentDidMount() {
		console.log('test');
	}
	render() {
		return (
			<div className={classNames("section", "faq")}>
				<div className="row">
					<div className={classNames("col-4", "faq-title", "bob")}>
						<h2>
							<div>
								Frequently
							</div>
							<div>
								Asked
							</div>
							<div>
								Questions
							</div>
						</h2>
					</div>
					<div className={classNames("col-8", "faq-content")}>
						<Accordion
							activeColor="secondary"
							collapses={[
								{
									title: "How does WeVett provide support to agents?",
									content:
										<>
											All of our clients are vetted as serious buyers or sellers before we recommend a real estate agent to them. That means that you will be working with clients who are largely more motivated than if you had received their information from other online or marketing sources.
											<br /><br />
											We will personally call you to discuss the client’s situation and then do a digital introduction between you and the client to get you both connected together.
											<br /><br />
											Through-out the remainder of the real estate transaction, your WeVett contact will continue to check in with both you and the client to make sure communication, expectations, etc. are all going smoothly.
										</>
								},
								{
									title: "What are WeVett's basic requirements for agents?",
									content:
										<>
											We require WeVett recommended agents to be in good standing within the real estate industry.
											<br /><br />
											At a minimum, we also require the following:
											<br />
											<ul><li>excellent communication skills</li>

												<li>trustworthy, reliable and good natured</li>

												<li>full-time status</li>

												<li>minimum 2 years’ experience</li>

												<li>active sales volume (relative to local standard)</li>

												<li>virtual showing capabilities</li>

												<li>digital documentation capabilities (emailing, e-signature software, etc.)</li></ul>

											Additional requirements will be addressed on a per client basis.
										</>
								},
								{
									title: "What does WeVett expect from agents on an ongoing basis?",
									content:
										<>
											We expect all WeVett recommended agents who are actively working with a WeVett client to do the following:
											<br />
											<ul><li>maintain regular and proactive communication with the client</li>

												<li>maintain regular and proactive communication with WeVett, including but not limited to responding to check in calls/emails, updating WeVett on client or contract status, notifying WeVett of any issues that arise</li>

												<li>notify WeVett when client is under contract and provide closing date, address and other relevant information</li>

												<li>send referral checks in a timely manner</li></ul>
										</>
								},
								{
									title: "As a WeVett recommended agent, how many clients can you expect to be matched with per year?",
									content:
										<>
											WeVett works with clients in every area of the country, however, our client base is primarily Active-Duty Military. This means that if you don’t work near a military installation, then our volume will be lower for your area.
											<br /><br />
											If you do work in an area with a large military community, we will discuss potential volume and your availability directly.
										</>
								},
								{
									title: "What is WeVett's referral fee structure?",
									content:
										<>
											We have an industry standard referral fee structure that caps at 28% of the agent commission.
										</>

								}
							]}
						/>
					</div>
				</div>
			</div>
		)
	}
}