import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
//import Button from 'components/CustomButtons/Button.js';
//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import scss
import "assets/css/pages/AboutPage/section6.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section


export default function Section6() {

	return (
		<div className={classNames("section", "core-values")}>
			<div className={classNames("row")}>
				<h2>Values We Live By</h2>
				<div className="col-12 core-values-wrapper">
					<div className="row">
						<div className="col-6">
							<div className="quote-header">
								<h3>
									Live with Passion and Enthusiasm
								</h3>
							</div>
							<div className="quote-body">
								<p>
									The most successful people are
									full of enthusiasm. There is an
									unmistakable fire in their eyes.
									They speak and act with passion.”
									<br />
									- Wayne Gerard Trotman
								</p>
							</div>
						</div>
						<div className="col-6">
							<div className="quote-header">
								<h3>
									Pursue Meaningful Connection Daily
								</h3>
							</div>
							<div className="quote-body">
								<p>
									“We cannot live only for ourselves. A
									thousand fibers connect us with our fellow
									men; and among those fibers, as sympathetic
									threads, our actions run as causes, and they
									come back to us as effects.”
									<br />
									- Herman Melville
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<div className="quote-header">
								<h3>
									Win with Integrity
								</h3>
							</div>
							<div className="quote-body">
								<p>
									“Real Integrity is doing the right
									thing, knowing that nobody
									knows you’re doing it or not.”
									<br />
									- Oprah Winfrey
								</p>
							</div>
						</div>
						<div className="col-6">
							<div className="quote-header">
								<h3>
									Be a Buffalo
								</h3>
							</div>
							<div className="quote-body">
								<p>
									With ordinary talent and extraordinary
									perseverance, all things are attainable.
									<br />
									- Thomas Fowell Buxton
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}