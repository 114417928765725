import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
//import Button from 'components/CustomButtons/Button.js';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
//import scss
import "assets/css/pages/AboutPage/section4.scss";

import { makeStyles } from "@material-ui/core/styles";
import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

const style = {
	...imagesStyles
};

const useStyles = makeStyles(style);
//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section


export default function Section4() {
	const classes = useStyles();

	return (
		<div className={classNames("section", "the-team")}>
			<div className={classNames("row")}>
				<h2>The Team</h2>
				<div className="col-12 team-wrapper">
					
					<div className={classNames("card-wrapper", "col-3")}>
						<Card className={classNames("gray-background", "card")}>
							<img
								src={require(`assets/img/about/team/shannon.jpg`)}
								className={classNames(classes.imgCardTop)}
								alt="Shannon Kaufman"
							/>
							<CardBody className="card-body">
								<h3>Shannon Kaufman</h3>
								<h4>
									Owner
								</h4>
							</CardBody>
						</Card>
					</div>
					<div className={classNames("card-wrapper", "col-3")}>
					<Card className={classNames("gray-background", "card")}>
						<img
							src={require(`assets/img/about/team/pat.jpg`)}
							className={classNames(classes.imgCardTop)}
							alt="Pat Washburn"
						/>
						<CardBody className="card-body">
							<h3>Pat Washburn</h3>
							<h4>
								Real Estate Broker
							</h4>
						</CardBody>
					</Card>
					</div>
					<div className={classNames("card-wrapper", "col-3")}>
						<Card className={classNames("gray-background", "card")}>
							<img
								src={require(`assets/img/about/team/mardie.jpg`)}
								className={classNames(classes.imgCardTop)}
								alt="Mardie Marqueze"
							/>
							<CardBody className="card-body">
								<h3>Mardie Marqueze</h3>
								<h4>
									Sales Director
								</h4>
							</CardBody>
						</Card>
					</div>
					<div className={classNames("card-wrapper", "col-3")}>
						<Card className={classNames("gray-background", "card")}>
							<img
								src={require(`assets/img/about/team/jason.jpg`)}
								className={classNames(classes.imgCardTop)}
								alt="Jason Pomeroy"
							/>
							<CardBody className="card-body">
								<h3>Jason Pomeroy</h3>
								<h4>
									Military Advisor
								</h4>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		</div>
	);
}