import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components

import agentImg from "assets/img/agents/team1.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AgentsPage/section6.scss";

export default function Section6() {

	return (
		<div className={classNames("agent-section-1", "section", "agent-section-6")}>
			<Card className={classNames("card-split", "agents-card")}>
				<CardBody className={classNames("card-body-left-nopadding", "half-width")}>
					<div>
						<h2>
						Ready to join our team<br />
						of top military agents?
					</h2>
					<Button type="button" color="secondary" className={classNames("agent-form-btn")}>
						Get Started!
					</Button>
					</div>
				</CardBody>
				<div className={classNames("half-width", "agents-card-center-image")}>
					<img src={agentImg} alt="Team of people standing next to each other" />
				</div>
			</Card>
		</div>
	);
}
