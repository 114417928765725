import React, { useState, useRef } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from 'react-responsive';
import useDebounce from "components/Hooks/Debounce";
// core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
//import Button from "components/CustomButtons/Button.js";

//import cardBlog5 from "assets/img/examples/card-blog5.jpg";

import cardsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.js";
//import { card } from "assets/jss/material-kit-pro-react";

const style = {
  ...cardsStyle
};

const useStyles = makeStyles(style);

function debounce(func, delay) {
  let timer;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, arguments), delay);
  };
}

export default function CardExampleCardRotating({front, back, targetCard, cardStyle, cardId}) {
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 980px)'
  });
  
  //const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  //const isMobile = useMediaQuery({ query: '(max-width: 980px)' });
  //const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  //const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

	const [cardState, setCardState] = useState(0);
  const [clickState, setClickState] = useState(0);
  const [scrollState, setScrollState] = useState(0);
  const debouncedValue = useDebounce(clickState, 10000);
  //const [scrollProgress, setScrollProgress] = React.useState(0); //scroll difference?
  const [activeRotate2, setActiveRotate2] = React.useState("");
  

  React.useEffect(() => {
    addStylesForRotatingCards();
	//updateCardImages();
	//setCardState(1);
    return function cleanup() {};
  });

  React.useEffect(() => {
    if(!cardId){
      return;
    }
    if( scrollState === 1 ){
      return;
    }
    //if(clickState === 0){ setClickState(1); }//reset click state
    //const flipElem = document.querySelectorAll('#' + cardId)[0];
    let computeProgress = () => {
      // The scrollTop gives length of window that has been scrolled
      //const scrolled = document.documentElement.scrollTop;
      //var scrolled = scrollProgress
      const w = window.scrollY;
      const h = document.documentElement.clientHeight;
      const elem = document.querySelectorAll('#' + cardId)[0];
      let progress = 0;
      if(elem && elem.offsetParent !== null){
        progress = 100 * (w + h - (elem.offsetTop + elem.offsetParent.offsetTop)) / h;
      } else {
        progress = 0;
      }
    
      if(progress > 85 ){
        setCardState(1);
        setActiveRotate2(classes.activateRotate);
      } else {
        
          setCardState(0);
          setActiveRotate2("");
      }
    }

    window.addEventListener("scroll", computeProgress);
    
    return function cleanup() {
      window.removeEventListener("scroll", computeProgress);
     
    }
  });

  function flipCard() {
    if(cardState === 0){
      setCardState(1);
      setActiveRotate2(classes.activateRotate);
      console.log('state was 0');
      console.log(activeRotate2);
    } else {
      setCardState(0);
      setActiveRotate2("");
      console.log('state was 1');
      console.log(activeRotate2);
    }
    //debouncedValue = 1; 
    setScrollState(1); //disable scroll events
    setClickState(1); //start timer
  }

  //if card has been manually flipped, set delay to disable scroll event (3 sec?)
  React.useEffect(() => {

    //Write Your Function Here!!
    console.log("Debounce val:", debouncedValue);
    console.log("Click State:", clickState);
    //console.log("Scroll State:", scrollState);
    //setScrollState(0); //reset scroll state to enable scroll events
    if(clickState === 1 && debouncedValue === 1){
      setClickState(0);
      setScrollState(0);
    }
    }, [debouncedValue, clickState]);

  const debouncedUpdateOverlay = useRef(
    debounce((cardState, targetCard) => {
      const uncoverCards = document.getElementsByClassName(targetCard);
      for (let i = 0; i < uncoverCards.length; i++) {
        const cardToUncover = uncoverCards[i];
        const overlayElem = cardToUncover.querySelector('.overlay');
        if (cardState) {
          overlayElem.style.opacity = 0; // Show image
          setActiveRotate2(classes.activateRotate);
        } else {
          overlayElem.style.opacity = 0.8; // Hide image
          setActiveRotate2("");
        }
      }
    }, 70) // Adjust the delay (in milliseconds) as needed
  ).current;

  React.useEffect(() => {
    debouncedUpdateOverlay(cardState, targetCard);
  }, [cardState, targetCard, debouncedUpdateOverlay]);

  
  const addStylesForRotatingCards = () => {
    var rotatingCards = document.getElementsByClassName(classes.cardRotate);
    for (let i = 0; i < rotatingCards.length; i++) {
      var rotatingCard = rotatingCards[i];
      var cardFront = rotatingCard.getElementsByClassName(classes.front)[0];
      var cardBack = rotatingCard.getElementsByClassName(classes.back)[0];
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      var rotatingWrapper = rotatingCard.parentElement;
      var cardWidth = rotatingCard.parentElement.offsetWidth;
      var cardHeight = rotatingCard.children[0].children[0].offsetHeight;
      rotatingWrapper.style.height = cardHeight + "px";
      rotatingWrapper.style["margin-bottom"] = 30 + "px";
      cardFront.style.height = "unset";
      cardFront.style.width = "unset";
      cardBack.style.height = "unset";
      cardBack.style.width = "unset";
      cardFront.style.height = cardHeight + 35 + "px";
      cardFront.style.width = cardWidth + "px";
      cardBack.style.height = cardHeight + 35 + "px";
      cardBack.style.width = cardWidth + "px";
    }
		
  };
  const classes = useStyles();
  return (
        <div id={cardId} className={isDesktopOrLaptop ? "desk " + classes.rotatingCardContainer + " " + classes.manualRotate + " " + activeRotate2 : classes.rotatingCardContainer + " " + classes.manualRotate + " " + activeRotate2} onMouseOut={isDesktopOrLaptop ? () => setCardState(0) : null } onMouseOver={isDesktopOrLaptop ? () => setCardState(1) : null } onClick={isDesktopOrLaptop ? null : () => flipCard()}>
          <Card background className={classes.cardRotate}>
            <div
              className={`${classes.front} ${classes.wrapperBackground}`}
              style={cardStyle}
            >
              <CardBody background className={classes.cardBodyRotate}>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <h3 className={classes.cardTitleBlack}>
                    {front.title}
                  </h3>
                </a>
                <p className={classes.cardDescriptionBlack}>
                  {front.desc}
                </p>
              </CardBody>
            </div>
            <div
              className={`${classes.back}`}
              
            >
              <CardBody background className={classes.cardBodyRotate}>
                <h5 className={classes.cardBackTitleBlack}>
					{back.title}
					</h5>
                <p className={classes.cardDescriptionBlack}>
                  {back.desc}
                </p>
              </CardBody>
            </div>
          </Card>
        </div>
  );
}