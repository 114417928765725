import React, { useState, useEffect, useCallback } from "react";
//import ReactDOM from "react-dom";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps"; //and ZoomableGroup
import { geoCentroid } from "d3-geo";
import { Annotation, AnnotationCallout } from "react-annotation";
//import Icon from "@material-ui/core/Icon";
import imagesLoaded from "imagesloaded";

/* TO DO */
//update image handling for popup so thatt every state change does not require re-loading the image (cache?)
// Create an object to cache images
//update mobile image position (left-right) depending on state position
  //create class for each side
  //img-left{ left: 0; right: unset; }
  //img-right{ right: 0; left: unset; }



//const imageCache = {};

const Popup = ({ data, position, isVisible }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Track image loading state
  var posX = position ? position[0] : 0; //-124.410798 to -66.979601
  var posY = position ? position[1] : 0; //25.887551 to 47.461219

  var leftEdge = 10;
  var rightEdge = 90;
  var wLong = -124.410798;
  var eLong = -66.979601;

  var topEdge = 0;
  var botEdge = 100;
  var nLat = 47.461219;
  var sLat = 25.887551;

  var calcX =
    ((rightEdge - leftEdge) * (posX - wLong)) / (eLong - wLong) + leftEdge;
  var calcY = ((botEdge - topEdge) * (posY - nLat)) / (sLat - nLat) + topEdge;

  var offsetX = 260; // base element offset
  var imageOffsetX = 200; //image offset 180
  //var offsetY = -20;
  var imageOffsetY = 0; //-150
  //var imageOffsetY = 0;
  var imageTop = 0;
  var imageBot = "unset";
  var mobileImageClassX = "";

  if (calcY > 50) {
    calcY = 20;
    imageOffsetY = 0; //no offset for lower states
  } else {
    calcY = 10;
    //have to reposition image to bottom for upper states, so leave the offset
    offsetX = offsetX * 1.2; //additional offset for upper states
    imageBot = "-120px"; //0
    imageTop = "unset";
  }

  if (calcX < 50) {
    //offsetX = offsetX + imageOffsetX
    //offsetX = -offsetX;
    //offsetX = offsetX;
    imageOffsetX = -imageOffsetX;
    mobileImageClassX = "img-right";
    //calcX = 100;
  } else {
    //calcX = calcX - (element width)
    offsetX = - (offsetX + imageOffsetX);
    mobileImageClassX = "img-left";
    //offsetX = 20;
    //calcX = 0;
  }


  //calcY = 20;

  //calcX = 100 - calcX;

  const style = {
    position: "absolute",
    left: calcX + "%",
    top: calcY + "%",
    transform: `translate(${offsetX}px, 0)`, // Adjust the positioning as needed
    opacity: isVisible ? 1 : 0, // Apply fade-in effect
    transition: "opacity 0.5s ease-in-out" // CSS transition for opacity
  };
  const style2 = {
    transform: `translate(${imageOffsetX}px, ${imageOffsetY}px)`, // Adjust the positioning as needed
    top: imageTop,
    bottom: imageBot,
    opacity: isImageLoaded ? 1 : 0, // Apply fade-in effect
    transition: "opacity 0.5s ease-in-out" // CSS transition for opacity
  };

  // Preload the image and store it in the cache
  useEffect(() => {
    if (isVisible && data.image ) {
      //const img = new Image();
      //img.src = data.image;
      //imageCache[data.image] = img;
      imagesLoaded( document.querySelectorAll('.img-div')[0] , ()=>{
        setIsImageLoaded(true); // Mark the image as loaded
      })
        /*
      img.onload = () => {
        // Store the image in the cache
        imageCache[data.image] = img;
        //setIsImageLoaded(true); // Mark the image as loaded
      };
      */

    } else {
      setIsImageLoaded(false); //turn off image until next one is loaded
    }
  }, [data.image, isVisible]);

  // Use the cached image if available and the image is loaded
  //const cachedImage = imageCache[data.image];
  
  //count description characters
  //const descLength = data.description.length;
  //add class to limit height


  return (
    <div className={`testElem ${isVisible ? "fade-in" : ""}`} style={style}>

      <div className="reviewText">
        <div className="rating">
          <span className="material-icons">
            grade
          </span>
          <span className="material-icons">
            grade
          </span>
          <span className="material-icons">
            grade
          </span>
          <span className="material-icons">
            grade
          </span>
          <span className="material-icons">
            grade
          </span>
        </div>
        <div className="review-text-div">
          <h2>&#34;{data.title}&#34;</h2>
          <h3>{data.subtitle}</h3>
          <p>{data.description}</p>
          <p>{data.clientName} | {data.clientCompany}</p>
        </div>

      </div>
      <div className={`img-div ${mobileImageClassX} ${isVisible && isImageLoaded ? "img-loaded fade-in" : ""}`} style={style2}>
        {/* Show the image only when it is loaded */}
        {isVisible && (
          <img alt="test_something" src={data.image} width={300} />
        )}
      </div>
    </div>
  );
};

const USMap = () => {
  // Data for the US map (replace with your data if needed)
  const usMapData = require("assets/geojson/us-states2.json");

  // Filter the states with reviews
  const statesWithReviews = usMapData.features.filter(
    (state) => state.properties.image && state.properties.description
  );

  // State to track the selected state index
  const [selectedStateIndex, setSelectedStateIndex] = useState(0);

  // Function to increment the selected state index
  const incrementSelectedStateIndex = useCallback(() => {
    //setIsImageLoaded(false); // Mark the image as not loaded
    setPopupVis(false);
    
    const fadeOutTimeout = setTimeout(() => {
      setSelectedStateIndex(
        (prevIndex) => (prevIndex + 1) % statesWithReviews.length
      );
      setPopupVis(true);
    }, 850); // Adjust the timeout duration as needed (should match the CSS transition duration)

    return () => clearTimeout(fadeOutTimeout);
  }, [statesWithReviews]);

  // Use useEffect to automatically cycle through data on page load
  useEffect(() => {
    if (statesWithReviews.length > 0) {
      const interval = setInterval(incrementSelectedStateIndex, 8000); // Change interval time as needed
      return () => clearInterval(interval);
    }
  }, [incrementSelectedStateIndex, statesWithReviews]);

  // create function to select and display state with review on click of state that has a review
  // should reset the automatic timer 'incrementSelectedStateIndex'
  // should also allow left/right arrows to cycle through states (similar to a carousel)

  const useMountEffect = (fun) => useEffect(fun, []);

  // Get the currently selected state's data
  const selectedStateData = statesWithReviews[selectedStateIndex];

  // State to track the position of the popup
  const [popupPosition, setPopupPosition] = useState(null);

  const [popupVisibility, setPopupVisibility] = useState(null);

  // Function to set the popup position
  const setPopupMarkerPosition = (coordinates) => {
    setPopupPosition(coordinates);
  };

  // Set popup position when selectedStateIndex changes
  useEffect(() => {
    if (selectedStateData) {
      setPopupMarkerPosition(geoCentroid(selectedStateData));
    }
  }, [selectedStateData]);

  // Function to set the popup visibility
  const setPopupVis = (vis) => {
    setPopupVisibility(vis);
  };

  function showReviewOnClick(ind){
    setSelectedStateIndex(ind);
    console.log("index :" + ind);
  }
  
  //starts cycling through states on element load
  useMountEffect(incrementSelectedStateIndex);

  return (
    <div id="testContainer" className="testContainer">
      <div className="map-container">
        {/* Render the react-simple-maps map */}
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={usMapData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.id}
                  geography={geo}
                  // Set the default state color to grey
                  style={{
                    default: {
                      fill: "#61B4E4", // Grey color for all states
                      stroke: "#FFF",
                      strokeWidth: 1,
                      outline: "none",
                      transition: "opacity 0.3s ease-in",
                      opacity: 1
                    },
                    hover: {
                      fill: "#61B4E4",
                      stroke: "none",
                      strokeWidth: 0.5,
                      outline: "none",
                      opacity: 0.8
                    },
                    pressed: {
                      fill: "#61B4E4",
                      stroke: "none",
                      strokeWidth: 0.5,
                      outline: "none",
                      opacity: 0.8
                    }
                  }}
                />
              ))
            }
          </Geographies>
          <Geographies geography={statesWithReviews}>
            {({ geographies }) =>
              geographies.map((geo, index) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  // Change the state color when selected
                  onClick={()=>showReviewOnClick(index)}
                  style={{
                    default: {
                      fill:
                        selectedStateData &&
                          geo.properties.name ===
                          selectedStateData.properties.name
                          ? "#F7931E"
                          : "#61B4E4",
                      stroke: "none",
                      strokeWidth: 0.5,
                      outline: "none",
                      transition: "fill 0.4s ease-in"
                    },
                    hover: {
                      fill: "#F7931E",
                      stroke: "none",
                      strokeWidth: 0.5,
                      outline: "none",
                      cursor: "pointer"
                    },
                    pressed: {
                      fill: "#F7931E",
                      stroke: "none",
                      strokeWidth: 0.5,
                      outline: "none"
                    }
                  }}
                />
              ))
            }
          </Geographies>
          {/* Render a marker in the center of the selected state */}
          {selectedStateData && (
            <Marker
              coordinates={geoCentroid(selectedStateData)}
            //coordinates={selectedStateData.geometry.coordinates[0][0]}
            >
              <circle r={5} fill="#436982" />
              {selectedStateData && (
                <Annotation
                  x={0}
                  y={0}
                  dx={geoCentroid(selectedStateData)[0] < -99 ? 20 : -20}
                  dy={-20}
                  color={"#436982"}
                  className="show-bg"
                  note={{
                    title: selectedStateData.properties.name,
                    align: "middle",
                    orientation: "topBottom",
                    bgPadding: 5,
                    bgColor: "#FFF",
                    padding: 0,
                    titleColor: "#000"
                  }}
                >
                  <AnnotationCallout />
                </Annotation>
              )}
            </Marker>
          )}
          {/* Render state details */}
        </ComposableMap>
      </div>
      {popupPosition && (
        <Popup
          data={selectedStateData.properties}
          position={popupPosition}
          isVisible={popupVisibility}
        />
      )}
    </div>
  );
};

export default USMap;
