import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import CardExampleCardRotating from "../Components/cardRotate";
// @material-ui/core components

import militaryImg from "assets/img/agents/Kitt.jpeg";
import agentImg from "assets/img/agents/jhh9.jpg";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AgentsPage/section2.scss";

export default function Section2() {

	return (
		<div className={classNames("section", "agent-section-2")}>
			<div className={classNames("row")}>
				<div className="half-width">
					<div className="col-4 desktop-only">
						<Card 
						background
						style={{
							backgroundImage: `url(${militaryImg})`
						}}
						className={classNames("img-card","serve")}
						>
							<CardBody background className={classNames("overlay", "overlay-blue")}>
							</CardBody>
						</Card>
					</div>
					<div className="col-7 desktop-only">
						<CardExampleCardRotating
							front={{
								title: "Who we serve",
								desc: ""
							}}
							back={{
								title: "",
								desc: `Our niche is active duty military homeownership.\n
								That means most of our clients are moving long-distance and know very little about their duty station and location.\n
								We can’t be the boots on the ground for them, but we can match them with the next best thing. You!`
							}}
							targetCard="serve"
		
						/>
					</div>
					<div className="col-1 desktop-only"></div>
					<div className="col-12 mobile-only" onClick={e => e.preventDefault()}>
						<h2>Who We Serve</h2>
						<CardExampleCardRotating
							front={{
								title: "",
								desc: ""
							}}
							back={{
								title: "",
								desc: `Our niche is active duty military homeownership.\n
								That means most of our clients are moving long-distance and know very little about their duty station and location.\n
								We can’t be the boots on the ground for them, but we can match them with the next best thing. You!`
							}}
							cardStyle={{
								backgroundImage: `url(${militaryImg})`
							}}
							cardId="serve-card-mobile"
							id="serve-card-mobile"
						/>
					</div>
				</div>
				<div className="half-width">
					<div className="col-1 desktop-only"></div>
					<div className="col-7 desktop-only">
						<CardExampleCardRotating
						front={{
							title: "Who we match",
							desc: ""
						}}
						back={{
							title: "Who we match",
							desc: `Every agent that gets matched is
							selected by hand.\n
							To work with our clients, we have a
							minimum standard that every
							recommended agent must meet.\n
							More importantly though, our goal is
							to seek the best all around fit for
							both client AND agent so that it’s a
							win-win for both parties.`
						}}
						targetCard="match"
			
						/>
					</div>
					<div className="col-4 desktop-only">
					<Card 
						background
						style={{
							backgroundImage: `url(${agentImg})`
						}}
						className={classNames("img-card","match")}
						>
							<CardBody background className={classNames("overlay", "overlay-blue")}>
							</CardBody>
						</Card>
					</div>
					<div className="col-12 mobile-only" onClick={e => e.preventDefault()}>
						<h2>Who We Match</h2>
						<CardExampleCardRotating
							front={{
								title: "",
								desc: ""
							}}
							back={{
								title: "",
								desc: `Every agent that gets matched is
								selected by hand.\n
								To work with our clients, we have a
								minimum standard that every
								recommended agent must meet.\n
								More importantly though, our goal is
								to seek the best all around fit for
								both client AND agent so that it’s a
								win-win for both parties.`
							}}
							cardStyle={{
								backgroundImage: `url(${agentImg})`
							}}
							cardId="match-card-mobile"
							id="match-card-mobile"
						/>
					</div>

				</div>
			</div>
		</div>
	);
}
