/*eslint-disable*/
import React from "react";
import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";

// sections for this page
import AgentCard from "./Sections/section1";
import Section2 from "./Sections/section2";
import Section3 from "./Sections/section3";
import Section4 from "./Sections/section4";
import Section5 from "./Sections/section5";
import Section6 from "./Sections/section6"; //Ready to join????

//mobile only
import Section3Mobile from "./Sections/section3_mobile";

import "assets/css/pages/home.scss";


//update header colors
//footer needed still

//style sheets
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "assets/css/pages/AgentsPage/AgentsPage.scss";
const useStyles = makeStyles(presentationStyle);

export default function AgentsPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	});
	const classes = useStyles();
	return (
		<div className="pageDiv">
			<Header
				brand="WeVett Realty"
				links={<HeaderLinks dropdownHoverColor="primary" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 80,
					color: "secondary"
				}}
			/>
			<div className={classNames(classes.main, classes.mainRaised, "main-content", "agents-main")}>
				<AgentCard />
				<Section2 />
        <MediaQuery minWidth={980}>
          {(matches) => 
            matches ? <Section3 /> : <Section3Mobile />
          }
        </MediaQuery>
				<Section4 />
				<Section5 id="agent-faqs" />
        <Section6 />
			</div>
			<CustomFooter />
		</div>
	);
}
