/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons'

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import "index.css";

// pages for this product
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import AgentsPage from "views/AgentsPage/AgentsPage";
import ServerTest from "views/test/testServerResponse";
import AboutPage from "views/AboutPage/AboutPage";
import CareersPage from "views/CareersPage/CareersPage";
import WvrCareersMain from "views/CareersPage/WvrCareersMain";
import DisclaimersPage from "views/DisclaimersPage/DisclaimersPage";
import FairHousingPage from "views/FairHousingPage/FairHousingPage";
import LicensesPage from "views/LicensesPage/LicensesPage";
import PrivacyPage from "views/PrivacyPage/PrivacyPage";
import ScrollTo from "components/Router/scrollTo";
//import ExperienceCoodinator from "views/CareersPage/Current/ExperienceCoordinator/ExperienceCoordinator";
//import SalesDirector from "views/CareersPage/Current/SalesDirector/SalesDirector";
//import VideoEditor from "views/CareersPage/Current/VideoEditor/VideoEditor";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

library.add(fas, faSquareInstagram);
var hist = createBrowserHistory();
//<Route path={`${process.env.PUBLIC_URL}/careers`} component={CareersPage} />
//<Route path={`${process.env.PUBLIC_URL}/careers/sales-director`} component={SalesDirector} />
//<Route path={`${process.env.PUBLIC_URL}/careers/video-editor`} component={VideoEditor} />
ReactDOM.render(
  <Router history={hist} basename={'/'}>
    <ScrollToTop />
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/servertest`} component={ServerTest} />
      <Route path={`${process.env.PUBLIC_URL}/agents`} component={AgentsPage} />
      <Route path={`${process.env.PUBLIC_URL}/careers/coordinator`} component={WvrCareersMain} />
      
      <Route path={`${process.env.PUBLIC_URL}/careersOld`} component={CareersPage} />
      <Route path={`${process.env.PUBLIC_URL}/careers`} component={WvrCareersMain} />
      <Route path={`${process.env.PUBLIC_URL}/disclaimer`} component={DisclaimersPage} />
      <Route path={`${process.env.PUBLIC_URL}/fair-housing`} component={FairHousingPage} />
      <Route path={`${process.env.PUBLIC_URL}/licenses`} component={LicensesPage} />
      <Route path={`${process.env.PUBLIC_URL}/privacy`} component={PrivacyPage} />
      <Route path={`${process.env.PUBLIC_URL}/about`} component={AboutPage} />
      
      <Route path={`${process.env.PUBLIC_URL}/faqs`} >
        <PresentationPage />
        <ScrollTo targetElementId="faqs" />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/reviews`}>
        <PresentationPage />
        <ScrollTo targetElementId="reviews" />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/`} component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
