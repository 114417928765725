import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/CareersPage/section1.scss";


export default function Section1() {

	return (
		<div className={classNames("section", "careers")}>
			<div className="row">
				<div className={classNames("col-12", "careers-col")}>
					<GridContainer spacing={10} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-careers">
						<GridItem xs={12} sm={12} md={12} key={1} className={classNames("item-wrapper")} style={{
							padding: 0
						}}>
							<div className="item-text">
								<div className="blue-header-block">
									<div className="boxed-sm">
									<h2>Careers</h2>
									</div>
								</div>
								<div className="careers-details">
									<p>
										We are not currently accepting employment applications, but feel free to email careers@wevett.com if you are interested, and we will keep you in mind if a position comes up.
										<br /><br />
										Thank you for your interest in WeVett Realty!
									</p>
								</div>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}