import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/LicensesPage/section1.scss";


export default function Section1() {

	return (
		<div className={classNames("section", "licenses")}>
			<div className="row">
				<div className={classNames("col-12", "licenses-col")}>
					<GridContainer spacing={10} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-licenses">

						<GridItem xs={12} sm={12} md={12} key={1} className={classNames("item-wrapper")}
							style={{
								padding: 0
							}}>
							<div className="item-text">
								<div className="blue-header-block">
									<h2>Licenses</h2>
								</div>
								<div className="license-details">
									<p>
										<b>Kansas Licensed Real Estate Brokerage</b>
										<br />
										<i>Brokerage License #:</i> CO00003195
										<br />
										<i>Designated Broker:</i> Patrick Washburn
										<br /><br />

										<b>WeVett Realty</b>
										<br />
										6828 E Pepperwood St
										<br />
										Wichita KS 67226
										<br />
										316-368-2758
									</p>
								</div>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}