import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components

//import agentImg from "assets/img/agents/MonicaDobbins.png";
import agentImg from "assets/img/agents/agent_man2.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AgentsPage/section1.scss";

export default function AgentCard() {

	return (
		<div className={classNames("agent-section-1", "section")}>
			<Card className={classNames("card-split", "agents-card")}>
				<CardBody className={classNames("card-body-left", "half-width")}>
					<h2>
						Passionate about working<br />
						with military?
					</h2>
					<p>
						We'd love to learn more about you and how you can help serve those who serve.
					</p>
					<Button type="button" color="secondary" className={classNames("agent-form-btn")}>
						I'm Interested!
					</Button>
				</CardBody>
				<div className={classNames("image-right", "img-overflow-md", "half-width")}>
					<img src={agentImg} alt="Sharply dressed male real estate agent" />
				</div>
			</Card>
		</div>
	);
}
