import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
//import Button from 'components/CustomButtons/Button.js';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
//import scss
import "assets/css/pages/AboutPage/section2.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section


export default class Section2 extends Component {
	constructor() {
		//do nothing
		super();
		console.log('loaded');
	}
	componentDidMount() {
		console.log('section 2 mounted');
	}
	render() {
		return (
			<div className={classNames("section", "mission-statement")}>
				<div className={classNames("row")}>
					<div className="col-12">
						<Card className={classNames("gray-background", "card")}>
							<CardBody className="card-body">
								<h3>
									We accomplish our mission by connecting active-duty military with
									 military specialized real estate agents who understand PCS moves and
									  can help families seamlessly navigate the real estate transaction.
								</h3>
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		);
	}

}