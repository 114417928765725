import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AgentsPage/section3.scss";

export default function Section3() {

	return (
		<div className={classNames("section", "serving-you")} >
			<div className="row">
				<div className={classNames("col-12", "serving-you-col")}>
					<GridContainer columnSpacing={10} rowSpacing={4} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-cont-icons">
						<GridItem xs={12} sm={12} md={4} key={0} className={classNames("item-wrapper")}>
							<div className="item-icon">
								<img alt="house icon 1" src={require("assets/img/agents/house1.png")} />
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} key={1} className={classNames("item-wrapper")}>
							<div className="item-icon">
								<img alt="house icon 2" src={require("assets/img/agents/house2.png")} />
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} key={2} className={classNames("item-wrapper")}>
							<div className="item-icon">
								<img alt="house icon 3" src={require("assets/img/agents/house3.png")} />
							</div>
						</GridItem>
						<div className="title-overlay">
							<h2>A real estate agent well served is a client well served</h2>
						</div>
					</GridContainer>
					<GridContainer columnSpacing={10} rowSpacing={4} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-cont">
						<GridItem xs={12} sm={12} md={4} key={0} className={classNames("item-wrapper")}>
							<Card className={classNames("item", "wiggle", "wiggle-1", "card-animate")}>
								<div className="item-text">
									<h3>Serious Buyers & Sellers</h3>
									<p>Every client we match
										with an agent has
										provided detailed
										information about their
										real estate goals. Our
										team conducts an
										onboarding call with the
										client to confirm intention
										and align expectations.
										Only after we connect
										with them, do we connect
										them with you.</p>
								</div>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} key={1} className={classNames("item-wrapper")}>
							<Card className={classNames("item", "wiggle", "wiggle-2", "card-animate")}>
								<div className="item-text">
									<h3>Support Team</h3>
									<p>Our team is working
										behind the scenes from
										connection to close. We
										maintain a close
										relationship with the client
										throughout the process to
										help with questions or
										concerns. We also
										regularly touch base with
										you as their agent to help
										all parties stay on the
										same page.</p>
								</div>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={12} md={4} key={2} className={classNames("item-wrapper")}>
							<Card className={classNames("item", "wiggle", "wiggle-3", "card-animate")}>
								<div className="item-text">
									<h3>Low Commission Splits</h3>
									<p>We were real estate
										agents ourselves and
										know how frustrating it
										can be to be gouged by
										steep referral fees. We
										intentionally keep our
										fees low and regard our
										referral agents as an
										extension of our own
										team. As top real estate
										agents, you deserve to be
										compensated fairly.</p>
								</div>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}