import React, { useState, useEffect } from 'react';

const ServerTest = () => {

	const [message, setMessage] = useState("");

  useEffect(() => {
    fetch("https://wevettrealty.com/server/php/test.php")
      .then((res) => res.json())
      .then((data) => setMessage(data.message));
  }, []);

	return (
		<>
			<h1>{message}</h1>
		</>
	);
}

export default ServerTest;