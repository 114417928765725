// ScrollToElement.js
import { useEffect } from 'react';

export default function ScrollTo({ targetElementId }) {
  useEffect(() => {
    const targetElement = document.getElementById(targetElementId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [targetElementId]);

  return null;
};


