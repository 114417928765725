/*eslint-disable*/
import React from "react";
//import MediaQuery from 'react-responsive';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import List from "@material-ui/core/List";
//import ListItem from "@material-ui/core/ListItem";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";

// sections for this page
import Section1 from "./Sections/section1";

//import "assets/css/pages/home.scss";

//style sheets
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import "assets/css/pages/AgentsPage/AgentsPage.scss";
const useStyles = makeStyles(presentationStyle);

export default function CareersPage() {
	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
    addClickEvents();
	});
	const classes = useStyles();
	return (
		<div className="pageDiv">
			<Header
				brand="WeVett Realty"
				links={<HeaderLinks dropdownHoverColor="primary" />}
				fixed
				color="transparent"
				changeColorOnScroll={{
					height: 80,
					color: "secondary"
				}}
			/>
			<div className={classNames(classes.main, classes.mainRaised, "main-content", "about-main")}>
				<Section1 />
				
			</div>
			<CustomFooter />
		</div>
	);
}
