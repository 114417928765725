import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
//import Button from 'components/CustomButtons/Button.js';
//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import scss
import "assets/css/pages/AboutPage/section5.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section


export default function Section5() {

	return (
		<div className={classNames("section", "three-uniques")}>
			<div className={classNames("row", "bring-to-front")}>
				<h2>What Makes Us Unique</h2>
				<div className="col-12 uniques-wrapper">
					<div className="row">
						<div className="col-5">
							<div className="bubble secondary">
								<h3>
									Buy vs Rent
								</h3>
							</div>
						</div>
						<div className="col-7">
							<p>
								Not every military move has the right conditions for buying a home. We talk
								through the buy vs rent decision with you during every move to help you choose
								which will be the best option at each new location. This is our full service
								approach, no pressure to buy, no financial obligation to us, just trustworthy advice.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-5">
						<div className="bubble primary">
								<h3>
									20 Year <br />Client Vision
								</h3>
							</div>
						</div>
						<div className="col-7">
							<p>
								Our goal is that by the time you exit the military, you have a paid for or nearly paid
								for home. Although it seems impossible for many, this is a very real outcome if you
								make financially sound real estate decisions along the way.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-5">
						<div className="bubble tertiary">
								<h3>
									360 Degree View
								</h3>
							</div>
						</div>
						<div className="col-7">
							<p>
								We have a 360 view of military home ownership. We were military or military
								spouses ourselves, we are real estate agents, and our sister company, WeVett
								Home Loans, is an expert VA loan team. All three facets are vitally important to
								having a successful real estate transaction while on active-duty.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className={classNames("grid-image", "send-to-back")}>
				<image src={require('assets/img/about/grid3.png')} width="800" alt="grid" />
			</div>
		</div>
	);
}