import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AboutPage/section3.scss";


export default function Section3() {

	return (
		<div className={classNames("section", "our-story")}>
			<div className="row">
				<div className={classNames("col-12", "our-story-col")}>
					<div className="item-text">
						<h2>Our Story</h2>
						<p>
							WeVett Realty was founded in 2018 by a group of active and former military seeking to
							alleviate a problem in the military relocation industry - chaotic, financially harmful real estate
							purchases led by real estate agents who did not understand the demands of military life.
						</p>
						<p>
							By focusing on the selection of high-quality, specialized real estate agents to help with long
							distance, VA loan purchases, WeVett Realty was able to help many home buyers avoid the
							woes that plagued the rest of the industry.
						</p>
						<p>
							Now, many military families have returned to use WeVett’s services for their third and even
							fourth PCS move.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}