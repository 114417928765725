/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

import Footer from "components/Footer/Footer.js";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function CustomFooter() {
	const classes = useStyles();
	return (<Footer
		theme="dark"
		content={
			<div>
				<div className="footer-links">
					<List className={classes.list}>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/`} className={classes.block}>Home
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
							<Link to={`${process.env.PUBLIC_URL}/about`} className={classes.block}>About
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/careers#careers-main`} className={classes.block}>Careers
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/licenses`} className={classes.block}>Licenses
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/disclaimer`} className={classes.block}>Disclaimer
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/fair-housing`} className={classes.block}>Fair Housing
							</Link>
						</ListItem>
						<ListItem className={classes.inlineBlock}>
						<Link to={`${process.env.PUBLIC_URL}/privacy`} className={classes.block}>Privacy
							</Link>
						</ListItem>
					</List>
				</div>
			</div>
		}
	/>
	);
}