import React from "react";
import "./style.css";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import CustomFooter from "components/CustomFooter/CustomFooter.js";
import { Link } from "react-router-dom";
//import Button from "components/CustomButtons/Button.js";

export default function WvrCareersMain() {
  return (
    <div className="pageDiv">
      <Header
        brand="WeVett Realty"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 80,
          color: "secondary"
        }}
      />
      <div id="careers-main" className="WVR-careers-main">
        <div className="div">
          <div className="overlap">
            <div className="rectangle" />
            <div className="boxed-1440">
              <div className="flexcontainer">
                <div className="text-wrapper">Careers</div>
                <p className="text">
                  <span className="span">
                    Ready to join a team that has fun, takes care of each other and strives for excellence in all we do?
                  </span>
                </p>
                <p className="text">
                  <a href="#available-positions"><span className="text-wrapper-2">Apply Below!</span></a>
                </p>
              </div>
              <div className="image-group">
                <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
                <div className="rectangle-2" />
                <img className="img" alt="Mask group" src="/img/mask-group-1.png" />
                <img className="mask-group-2" alt="Mask group" src="/img/mask-group-2.png" />
                <img className="mask-group-3" alt="Mask group" src="/img/mask-group-3.png" />
              </div>

            </div>
          </div>

          <div id="available-positions" className="available-positions">
            <div className="boxed-1440">
              <h2>Available Positions</h2>
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Work Type</th>
                    <th>Location</th>
                    <th>Department</th>
                    <th className="show-mbl"></th>
                    <th className="hide show-mbl"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="hide">
                    <td className="show-mbl job-title"><Link to={`/careers/sales-director`}>Realty Experience and Sales Director</Link></td>
                    <td>Full-Time</td>
                    <td>Flexible</td>
                    <td>Operations</td>
                    <td className="show-mbl"><Link to={`/careers/sales-director`}>Apply Here &gt;</Link></td>
                  </tr>
                  <tr className="hide">
                    <td className="show-mbl job-title"><Link to={`/careers/coordinator`}>Experience Coordinator</Link></td>
                    <td>Full-Time</td>
                    <td>Flexible</td>
                    <td>Operations</td>
                    <td className="show-mbl"><Link to={`/careers/coordinator`}>Apply Here &gt;</Link></td>
                  </tr>
                  <tr className="hide">
                    <td className="show-mbl job-title"><Link to={`/careers/video-editor`}>Video Editor</Link></td>
                    <td>Freelance</td>
                    <td>Flexible</td>
                    <td>Marketing</td>
                    <td className="show-mbl"><Link to={`/careers/video-editor`}>Apply Here &gt;</Link></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="overlap-group">
            <div className="boxed-1440">
              <div>
                <img className="icon-search" alt="Icon search" src="/img/icon-search.png" />
              </div>
              <div className="text-grp-1">
                <h3>Looking for a different opportunity?</h3>
                <p>We love creative thinkers and are always open to meeting new people. Tell us more about you!</p>
                <a href='mailto:careers@wevett.com' target='_blank' rel="noreferrer noopener"><button type="button" className="blue-btn">Send Us A Message</button></a>

              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomFooter />
    </div>
  );
};
