import React from "react";
//import ReactDOM from "react-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

//images
/*
import image1 from "assets/img/about/couple_in_front_of_house_1.jpg";
import image2 from "assets/img/about/couple_in_house.jpg";
import image3 from "assets/img/about/couple_in_mountains.jpg";
import image4 from "assets/img/about/couple_in_front_of_house_2.jpg";
import image5 from "assets/img/about/kitt.jpeg";
import image6 from "assets/img/about/meeting_room.jpg";
*/
import "assets/css/pages/AboutPage/section1.scss";

const CardImage = ({ imageName, imageAlt, mobile, desktop }) => {
	if (!imageName) {
		return null;
	}
	var displayFlag = '', yPos = 0;
	//let imageURL = imageName;

	if(mobile.display === true && desktop.display === true){
		displayFlag = 'display-both';
		yPos = mobile.yPos; //fix handling for this
	} else {
		if(mobile.display !== true){
			if(desktop.display !== true){
				displayFlag = 'no-display';
			} else {
				displayFlag = 'desktop-display';
				yPos = desktop.yPos;
			}
		} else {
			displayFlag = 'mobile-display';
			yPos = mobile.yPos;
		}
	}
	//let image_obj = require(imageName);
	//let y_base = 10;
	let y_position = yPos;
	let y_pos_out = y_position + "px";


	const style = {
		position: "relative",
		top: y_pos_out
	};

	return (
		<div className={classNames("card-image-wrapper", "col-2", displayFlag)} style={style}>
			<img src={require(`assets/img/about/${imageName}`)} alt={imageAlt} width="300" />
		</div>
	)
}

const ColumnSection = () => {
	let images = [
		{
			fn: "jumping_in_front_of_mountain.jpg",
			alt: "person jumping in front of mountain",
			mobile: {
				display: true,
				yPos: 35
			},
			desktop: {
				display: false,
				yPos: 40
			}
		},
		{
			fn: "couple_in_front_of_house_1.jpg",
			alt: "Couple standing in front of stone house",
			yPos: 10,
			mobile: {
				display: false,
				yPos: 100
			},
			desktop: {
				display: true,
				yPos: 100
			}
		},
		{
			fn: "couple_in_house.jpg",
			alt: "Couple sitting at a table",
			yPos: 15,
			mobile: {
				display: false,
				yPos: 55
			},
			desktop: {
				display: true,
				yPos: 150
			}
		},
		{
			fn: "couple_in_house_mobile.jpg",
			alt: "Couple sitting at a table in their house",
			yPos: 15,
			mobile: {
				display: true,
				yPos: 55
			},
			desktop: {
				display: false,
				yPos: 150
			}
		},
		{
			fn: "couple_in_mountains.jpg",
			alt: "Couple standing in field with mountains in the distance",
			yPos: 12,
			mobile: {
				display: false,
				yPos: 120
			},
			desktop: {
				display: true,
				yPos: 120
			}
		},
		{
			fn: "couple_in_front_of_house_2.jpg",
			alt: "Couple standing in front of brick house",
			yPos: 16,
			mobile: {
				display: false,
				yPos: 160
			},
			desktop: {
				display: true,
				yPos: 160
			}
		},
		{
			fn: "kitt.jpeg",
			alt: "Air Force servicemember getting into jet plane",
			yPos: 9,
			mobile: {
				display: false,
				yPos: 90
			},
			desktop: {
				display: true,
				yPos: 90
			}
		},
		{
			fn: "meeting_room.jpg",
			alt: "Group of three meeting at a conference table",
			yPos: 0,
			mobile: {
				display: true,
				yPos: 0
			},
			desktop: {
				display: true,
				yPos: 0
			}
		}
	];


	return (
		<div className="section-inner">
			<div className="upper-wrapper">
				<h1>Mission:</h1>
				<h2>To bring consistency to the PCS process, making military moves easier.</h2>

				<div className="path-arrow-wrapper">
					<img className="path-arrow background-img desktop-display" src={require("assets/img/about/arrow_v2.png")} alt="path with arrow" width="1200" />
					<img className="path-arrow background-img mobile-display" src={require("assets/img/about/Arrow_small.png")} alt="small path with arrow" width="1200" />
				</div>
			</div>
			<div className="image-spacer" images={images}>
				{
					images.map((image) => (
						<CardImage
							imageName={image.fn}
							imageAlt={image.alt}
							mobile={image.mobile}
							desktop={image.desktop}
						/>
					))
				}
			</div>
		</div>
	)
}

export default function Section1() {

	return (
		<div className={classNames("section", "about-us-1")}>
			<div className={classNames("row", "fullwidth")}>
				<div className={classNames("col-12", "about-us-col")}>
					<div>

						<ColumnSection />
					</div>
				</div>
			</div>
		</div>
	);
}