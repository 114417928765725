import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/PrivacyPage/section1.scss";


export default function Section1() {

	return (
		<div className={classNames("section", "privacy")}>
			<div className="row">
				<div className={classNames("col-12", "privacy-col")}>
					<GridContainer spacing={10} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-privacy">
						<GridItem xs={12} sm={12} md={12} key={1} className={classNames("item-wrapper")} style={{
								padding: 0
							}}>
							<div className="item-text">
								<div className="blue-header-block">
									<h2>Privacy Policy</h2>
								</div>
								<div className="privacy-details">
									<p>
										We receive, collect and store any information you enter on our website or provide us in any other way. In addition, we collect the Internet protocol (IP) address used to connect your computer to the Internet; login; e-mail address; password; computer and connection information and purchase history. We may use software tools to measure and collect session information, including page response times, length of visits to certain pages, page interaction information, and methods used to browse away from the page. We also collect personally identifiable information (including name, email, password, communications); payment details (including credit card information), comments, feedback, product reviews, recommendations, and personal profile.
										<br /><br />
										When you conduct a transaction on our website, as part of the process, we collect personal information you give us such as your name, address and email address. Your personal information will be used for the specific reasons stated above only.
										<br /><br />
										We collect such Non-personal and Personal Information for the following purposes:
										<br /><br />
										<ul>
											<li>To provide and operate the Services;</li>

											<li>To provide our Users with ongoing customer assistance and technical support;</li>

											<li>To be able to contact our Visitors and Users with general or personalized service-related notices and promotional messages;</li>

											<li>To create aggregated statistical data and other aggregated and/or inferred Non-personal Information, which we or our business partners may use to provide and improve our respective services;</li>

											<li>To comply with any applicable laws and regulations.​</li>
										</ul>
										<br />
										Our company is hosted on a dedicated server. Your data may be stored through our data storage, databases and the general applications. We store your data on secure servers behind a firewall.
										<br /><br />
										All direct payment gateways offered by and used by our company adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.
										<br /><br />
										We may contact you to notify you regarding your account, to troubleshoot problems with your account, to resolve a dispute, to collect fees or monies owed, to poll your opinions through surveys or questionnaires, to send updates about our company, or as otherwise necessary to contact you to enforce our User Agreement, applicable national laws, and any agreement we may have with you. For these purposes we may contact you via email, telephone, text messages, and postal mail.
										<br /><br />
										If you don’t want us to process your data anymore, please contact us at hello@wevett.com or send us mail to: WeVett 6828 E Pepperwood St., Wichita, KS 67226
										<br /><br />
										We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
										<br /><br />
										If you would like to: access, correct, amend or delete any personal information we have about you, you are invited to contact us at hello@wevett.com or send us mail to: WeVett 6828 E Pepperwood St., Wichita, KS 67226
									</p>
								</div>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}