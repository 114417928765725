import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";


//import scss
import "assets/css/pages/section4.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
    title: 'some title',
    description: 'description stuff',
    canonical: 'https://page',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'military-friendly, realtor,other tags'
        }
    }
}

//goes in return or component
<DocumentMeta {...meta} />

*/

export default class Section4 extends Component {
    constructor() {
        //do nothing
        super();
        console.log('loaded');
    }
    componentDidMount() {
        console.log('test');
    }
    render() {
        return (
            <div className={classNames("section", "the-process")}>
                <div className={classNames("row", "process-header")} >
                <div className="process-village-image mobile-only">
                                <img alt="Neighborhood outline" src={require("assets/img/test-images/icons/Neighborhood_outline.svg")} />
                            </div>
                    <h2>Our Proven Process</h2>
                    <div className={classNames("process-line-dashed", "process-line-horz")}>
                        <div></div>
                    </div>
                </div>
                <div className="row">
                    <div className={classNames("col-12", "process-col")}>
                        <GridContainer spacing={2} columns={{ xs: 12, sm: 12, md: 12 }} className="process-grid-cont">
                            <GridItem xs={12} sm={12} md={4} key={0} className={classNames("process-item-wrapper")}>
                                <div className="process-item">
                                    <div className="process-num">
                                        <span>01.</span>
                                        <div className={classNames("process-line-dashed", "process-line-horz")}>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="process-text">
                                        <h2>Data Analysis</h2>
                                        <span>Using real-time market data, we
                                            find the most qualified real estate
                                            agents in the area in which you
                                            are buying or selling.</span>
                                    </div>

                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4} key={1} className={classNames("process-item-wrapper")}>
                                <div className="process-item">
                                    <div className="process-num">
                                        <span>02.</span>
                                        <div className={classNames("process-line-dashed", "process-line-horz")}>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div className="process-text">
                                        <h2>Personal Interviews</h2>
                                        <span>We conduct in-depth interviews
                                            with qualified candidates to
                                            confirm their fit for your specific
                                            real estate needs.</span>
                                    </div>

                                </div>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} key={2} className={classNames("process-item-wrapper-corner", "process-item-wrapper")}>
                                <div className={classNames("process-item-corner", "process-item", "process-vertical")}>
                                    <div className={classNames("process-num", "process-icon-vertical")}>
                                        <span>03.</span>
                                        <div className={classNames("process-line-dashed", "process-line-vertical")}>
                                            <div></div>
                                        </div>
                                    </div>

                                    <div className="process-text">
                                        <h2>Connection & Support</h2>
                                        <span>Finally, we send you our top real
                                            estate agent recommendation.
                                            WeVett then provides ongoing
                                            support to both you and your
                                            agent.</span>
                                    </div>

                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8} key={3} className={classNames("process-item-wrapper", "process-row-2", "process-bottom-line", "process-cta")}>
                                <div>
                                    <span>Only three steps for a successful PCS<br className="mobile-only" /> - call <a href="tel:13166695273">316-669-5273</a> to get started!</span>
                                </div>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4} key={4} className={classNames("process-item-wrapper", "process-row-2")}>
                                <div className={classNames("process-item", "process-vertical")}>
                                    <div className={classNames("process-num", "process-icon-vertical")}>
                                        <span>04.</span>
                                    </div>
                                    <div className={classNames("process-text", "number-alignment")}>
                                        <h2>Every PCS</h2>
                                        <span>No matter if you decide to buy,
                                            sell or rent, our team is ready to
                                            help walk you through the
                                            decision before every PCS.</span>
                                    </div>

                                </div>
                            </GridItem>
                            <div className="process-image-left process-village-image">
                                <img alt="Neighborhood outline" src={require("assets/img/test-images/icons/Neighborhood_outline.svg")} />
                            </div>
                        </GridContainer>

                    </div>
                </div>
            </div>
        );
    }

}


