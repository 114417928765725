import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
//import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/FairHousingPage/section1.scss";


export default function Section1() {

	return (
		<div className={classNames("section", "fair-housing")}>
			<div className="row">
				<div className={classNames("col-12", "fair-housing-col")}>
					<GridContainer spacing={10} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-fair-housing">
						<GridItem xs={12} sm={12} md={12} key={1} className={classNames("item-wrapper")} style={{
							padding: 0
						}}>
							<div className="item-text">
								<div className="blue-header-block">
									<h2>Fair Housing</h2>
								</div>
								<div className="fair-housing-details">
									<p>
										WeVett Realty encourages and supports an affirmative advertising and marketing program in which there are no barriers to obtaining housing because of race, color, religion, sex, handicap, familial status, or national origin. All residential real estate information on this website is subject to the Federal Fair Housing Act Title VIII of the Civil Rights Act of 1968, as amended, which makes it illegal to advertise ” any preference, limitation, or discrimination because of race, color, religion, sex, handicap, familial states, or national origin, or intention to make any such preference, limitation or discrimination.” Your state or local jurisdiction may impose additional requirements. We are committed to the letter and spirit of the United States policy for the achievement of equal housing opportunity.
										<br /><br />
										WeVett Realty is an Equal Opportunity Employer and supports the Fair Housing Act.
									</p>
								</div>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}