import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

import Card from "components/Card/Card.js";
//import CardBody from "components/Card/CardBody";
import Button from 'components/CustomButtons/Button.js';
// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import agentImg from "assets/img/agents/Agent_1_cutout.png";
//import imagesStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import "assets/css/pages/AgentsPage/section4.scss";


export default function Section4() {

	return (
		<div className={classNames("section", "becoming-an-agent")}>
			<div className="row">
				<div className={classNames("col-12", "becoming-an-agent-col")}>
					<GridContainer spacing={10} columns={{ xs: 12, sm: 12, md: 12 }} className="grid-become-agent">
						<GridItem xs={12} sm={12} md={6} key={0} className={classNames("item-wrapper")}>
							<Card className={classNames("card-img","card-animate")}>
								<div className="item-img">
									<img alt="Man in business clothes" src={require("assets/img/agents/MonicaDobbins.png")} />
								</div>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} key={1} className={classNames("item-wrapper")}>
							<div className="item-text">
								<h2>Becoming a WeVett Agent</h2>
								<p>
									We do not have a preferred real estate agent network but
									choose agents for each specific client. That means
									whenever we have a client moving to an area, we will
									interview agents based off the client’s specific needs.
									<br /><br />
									However, we do offer agents the option of filling out our
									“base interview form.”
									<br /><br />
									The form gathers general background information and
									real estate history. Then if we do have a client moving to
									your specific city, we would follow up to do a more
									detailed interview to determine if you would be a good fit.
								</p>
								<Button type="button" color="secondary" className={classNames("agent-form-btn")}>Sign me up!</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		</div>
	);
}