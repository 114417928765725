import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
//import scss
import "assets/css/pages/section5.scss";
import Accordion from "components/Accordion/Accordion.js";

export default class Section5 extends Component {
    constructor() {
        //do nothing
        super();
        console.log('loaded');
    }
    componentDidMount() {
        console.log('test');
    }
    render() {
        return (
            <div className={classNames("section", "faq")} id="faqs" name="faqs">
                <div className="row">
                    <div className={classNames("col-4", "faq-title", "bob")}>
                        <h2>
                            <div>
                                Frequently
                            </div>
                            <div>
                                Asked
                            </div>
                            <div>
                                Questions
                            </div>
                        </h2>
                    </div>
                    <div className={classNames("col-8", "faq-content")}>
                        <Accordion
                            activeColor="secondary"
                            collapses={[
                                {
                                    title: "How long does it take to be matched with a real estate agent?",
                                    content:
                                        <>
                                            Our entire vetting process, from our first phone call with you to our recommendation typically takes 24-48 hours(during business days.) 
                                            That gives us time to research and coordinate all of our interviews.
                                             If you need an agent sooner, we can expedite our vetting process, just know we will not be able to vet as many candidates.
                                        </>
                                },
                                {
                                    title: "I already have a real estate agent, but it's not working out. Can WeVett help me find a different one?",
                                    content:
                                        <>Yes, but WeVett can only match you with a different real estate agent if you officially end the relationship with your current agent.
                                        <br/><br/>
                                        This will need to be a discussion between you and your current agent. If you signed a representation contract, you will both need to sign a document saying that the relationship has been terminated.
                                        </>
                                },
                                {
                                    title: "I'm not moving for several months, can I still contact WeVett?",
                                    content:
                                        <>Yes! We will help you get all your boxes checked before you need
										to actively start searching for a home. This includes helping you find a
										real
										estate
										agent
										who can familiarize you with the local area and neighborhoods.
                                        </>
                                },
                                {
                                    title: "How does WeVett get paid?",
                                    content:
                                        <>In the simplest way possible – we are paid a referral commission
										by the real estate agent when you close on a home. You pay us nothing out of
										pocket.
                                        <br/><br/>
                                        There are a lot of different models out there, but the main difference in
										what we
										do is
										that
										before ever considering any payment, we first find the right real estate
										agent
										for you.
                                        <br/><br/>
                                        That means we are searching for agents regardless of whether or not they
										will
										pay us.
										This
										allows us to search through the top agents that don’t typically subscribe to
										marketing
										or
										referral lists (because they generate enough business on their own.) In the
										real
										estate
										industry, it is then standard to pay referral fees out to another real
										estate
										agent if a
										client is outside of the referring agent’s service area.
                                        </>
                                },
                                {
                                    title: "Does WeVett help find rentals?",
                                    content:
                                        <>No. Unfortunately, at this time we are unable to help with
										finding rentals or rental real estate agents.
                                        <br/><br/>
                                        There are a few online sites such as Zillow, Apartments.com, Realtor.com,
										Hotpads, etc.
										that
										can give you a good idea of the available rentals in the area. That is a
										good
										place to
										start.
                                        <br/><br/>
                                        You can also search for local property management companies – they will have
										a
										list of
										available homes and apartments that they manage and can quickly coordinate
										showings and
										leases for you.
                                        </>
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
        )
    }
}