import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components

//import scss
import "assets/css/pages/camoBorder.scss";
import background from "assets/img/test-images/borders/Camo-Border.png";

export default function CamoBorder(){
	return (
		<div className={classNames("border-fullwidth", "fullwidth", "section", "camo")} style={{ backgroundImage: `url(${background})` }}>
		</div>
	);
}