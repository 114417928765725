import React, { Component } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Button from 'components/CustomButtons/Button.js';

//import scss
import "assets/css/pages/section6.scss";

//meta tags format
/*
import MetaTags from 'react-meta-tags';
const meta = {
	title: 'some title',
	description: 'description stuff',
	canonical: 'https://page',
	meta: {
		charset: 'utf-8',
		name: {
			keywords: 'military-friendly, realtor,other tags'
		}
	}
}

//goes in return or component
<DocumentMeta {...meta} />

*/

//Add 'Get matched with your agent' bar and CTA to bottom of section


export default class Section2 extends Component {
	constructor() {
		//do nothing
		super();
		console.log('loaded');
	}
	componentDidMount() {
		console.log('test');
	}
	render() {
		return (
			<div className={classNames("section", "military-pcs")}>
				<div className={classNames("row")}>
					<h2>Top real estate agents for your military PCS.</h2>
				</div>
				<div className={classNames("row", "row-narrow")}>
					<div className={classNames("col-4", "col-narrow")}>
						<div className={classNames("colInner")}>
							<div className={classNames("icon-row", "icon-container")}>
								<div className={classNames("icon-wrapper", "square")}>
									<img alt="Icon 1" src={require("assets/img/test-images/icons/1.svg")}></img>
								</div>
							</div>
							<div className={classNames("content-header-wrapper")}>
								<h3>Trustworthy</h3>
							</div>
							<div className={classNames("content-text")}>
								<p>We handpick agents that pass the "trust" test. If we wouldn't trust them with our own house keys, we won't recommend them to you.</p>
							</div>
						</div>
					</div>
					<div className={classNames("col-4", "col-narrow")}>
						<div className={classNames("colInner")}>
							<div className={classNames("icon-row", "icon-container")}>
								<div className={classNames("icon-wrapper", "square")}>
									<img alt="Icon 2" src={require("assets/img/test-images/icons/2.svg")}></img>
								</div>
							</div>
							<div className={classNames("content-header-wrapper")}>
								<h3>Experienced</h3>
							</div>
							<div className={classNames("content-text")}>
								<p>Tried-and-tested agents who know the real estate market, are experts at negotiating and have the sales numbers to back it up.</p>
							</div>
						</div>
					</div>
					<div className={classNames("col-4", "col-narrow")}>
						<div className={classNames("colInner")}>
							<div className={classNames("icon-row", "icon-container")}>
								<div className={classNames("icon-wrapper", "square")}>
									<img alt="Icon 3" src={require("assets/img/test-images/icons/3.svg")}></img>
								</div>
							</div>
							<div className={classNames("content-header-wrapper")}>
								<h3>Military-Minded</h3>
							</div>
							<div className={classNames("content-text")}>
								<p>Agents who understand how to negotiate with the VA loan, who can do virtual showings, and will go to bat for you when you can't be there in person.</p>
							</div>
						</div>
					</div>
				</div>
				<div className={classNames("row", "row-cta")}>
					<div className="col-6">
						<h3>
							Connect with a specialized agent
						</h3>
					</div>
					<div className="col-6">
					<Button type="button" color="secondary" className={classNames("client-form-btn")}>
						Start Here
					</Button>
					</div>
				</div>


			</div>
		);
	}

}